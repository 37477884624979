import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";

import Footer from "../../components/footer";
import Header from "../../components/header";
import NavMenu from "../../components/nav-menu";

import machining from "../../images/precision_machining.jpg";
import plasticInjection from "../../images/plastic_injection.jpg";
import brazingTablet from "../../images/brazing-tablet.webp";
import casting from "../../images/casting.jpg";
import assembly from "../../images/assembly.jpg";
import qualityAssurance from "../../images/quality_assurance.jpg";
import { messages } from "../../i18n-translations";

const ServicesPage: React.FC = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navTopMargin, setNavTopMargin] = React.useState(40);
  const header = useRef<HTMLDivElement>(null);

  const breakpoints = useBreakpoint();
  const isScreenXl = breakpoints.xl;
  const isScreenLg = breakpoints.lg;

  useEffect(() => {
    if (header.current) setNavTopMargin(header.current.clientHeight);
  }, [header]);

  return (
    <main className="relative z-0 w-screen bg-white">
      <div ref={header} className="fixed top-0 w-full bg-white z-110">
        <Header
          isNavOpen={isNavOpen}
          onMenuButtonPress={() => setIsNavOpen(!isNavOpen)}
          cn
        />
      </div>
      {
        //#region Mobile Nav
      }
      {!isScreenXl && (
        <AnimatePresence>
          {isNavOpen && (
            <div
              className={`fixed flex inset-0 z-101 w-screen h-screen bg-transparent`}
            >
              <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
                className="flex w-full origin-top"
                key="nav"
                style={{ marginTop: navTopMargin }}
              >
                <NavMenu cn />
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      )}
      {
        //#endregion
      }

      {
        //#region Header Image
      }
      <div style={{ marginTop: navTopMargin }} className="bg-white">
        <div className="relative">
          <div className="z-0 w-full h-full">
            {isScreenLg ? (
              <StaticImage
                src={"../../images/services-header.webp"}
                alt="About Us"
                layout="fullWidth"
              />
            ) : (
              <StaticImage
                src={"../../images/services-header-md.webp"}
                alt="About Us"
                layout="fullWidth"
              />
            )}
          </div>
          <div className="absolute inset-0 z-0 flex flex-col items-center justify-center text-white bg-black opacity-80">
            <h1 className="text-5xl">{messages.cn.services}</h1>
          </div>
        </div>
      </div>

      {
        //#endregion
      }

      {
        //#region Top Region
      }
      <div className="w-full py-8 ">
        <div className="px-4 mx-auto sm:px-10 lg:px-20 xl:container">
          <div className="hidden mb-4 text-2xl font-semibold text-center lg:block lg:mb-8">
            {messages.cn.precisionMachining}
          </div>
          <div
            className="flex flex-col items-center justify-center w-full lg:flex-row"
            id="machining"
          >
            <div className="flex justify-center w-10/12 px-4 pb-4 sm:w-full sm:px-10 lg:pb-0 lg:w-6/12">
              <img
                src={machining}
                alt="Precision Machining"
                className="w-auto h-full rounded-xl"
              />
            </div>
            <div className="self-stretch flex-1">
              <div className="flex flex-col w-full h-full pl-0 mb-6 text-left md:pl-10 md:mb-0">
                <div className="mt-1 mb-4 text-2xl text-center lg:mt-0 lg:mb-2 lg:text-left lg:hidden">
                  {messages.cn.precisionMachining}
                </div>
                <div className="my-auto">
                  <ul>
                    <li className="bullet-list">
                      {messages.cn.precisionMachiningText1}
                    </li>
                    <li className="bullet-list">
                      {messages.cn.precisionMachiningText2}
                    </li>
                    <li className="bullet-list">
                      {messages.cn.precisionMachiningText3}
                    </li>
                    <li className="bullet-list">
                      {messages.cn.precisionMachiningText4}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-8 bg-gray-200">
        <div className="px-4 mx-auto sm:px-10 lg:px-20 xl:container">
          <div className="hidden mb-4 text-2xl font-semibold text-center lg:block lg:mb-8">
            {messages.cn.plasticInjectionAndRubberCompression}
          </div>
          <div
            className="flex flex-col items-center justify-center w-full lg:flex-row"
            id="plasticInjection"
          >
            <div className="flex justify-center w-10/12 px-4 pb-4 sm:w-full sm:px-10 lg:pb-0 lg:w-6/12">
              <img
                src={plasticInjection}
                alt="Plastic Injection"
                className="w-auto h-full rounded-xl"
              />
            </div>
            <div className="self-stretch flex-1">
              <div className="flex flex-col w-full h-full pl-0 mb-6 text-left md:pl-10 md:mb-0">
                <div className="mt-1 mb-4 text-2xl text-center lg:mt-0 lg:mb-2 lg:text-left lg:hidden">
                  {messages.cn.plasticInjectionAndRubberCompression}
                </div>
                <div className="my-auto">
                  <ul>
                    <li className="bullet-list">
                      {messages.cn.plasticInjectionText1}
                    </li>
                    <li className="bullet-list">
                      {messages.cn.plasticInjectionText2}
                    </li>
                    <li className="bullet-list">
                      {messages.cn.plasticInjectionText3}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-8">
        <div className="px-4 mx-auto sm:px-10 lg:px-20 xl:container">
          <div className="hidden mb-4 text-2xl font-semibold text-center lg:block lg:mb-8">
            {messages.cn.brazing}
          </div>
          <div
            className="flex flex-col items-center justify-center w-full lg:flex-row"
            id="brazing"
          >
            <div className="flex justify-center w-10/12 px-4 pb-4 sm:w-full sm:px-10 lg:pb-0 lg:w-6/12">
              <img
                src={brazingTablet}
                alt="Brazing"
                className="w-auto h-full rounded-xl"
              />
            </div>
            <div className="self-stretch flex-1">
              <div className="flex flex-col w-full h-full pl-0 mb-6 text-left md:pl-10 md:mb-0">
                <div className="mt-1 mb-4 text-2xl text-center lg:mt-0 lg:mb-2 lg:text-left lg:hidden">
                  {messages.cn.brazing}
                </div>
                <div className="my-auto">
                  <ul>
                    <li className="bullet-list">{messages.cn.brazingText1}</li>
                    <li className="bullet-list">{messages.cn.brazingText2}</li>
                    <li className="bullet-list">{messages.cn.brazingText3}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-8 bg-gray-200">
        <div className="px-4 mx-auto sm:px-10 lg:px-20 xl:container">
          <div className="hidden mb-4 text-2xl font-semibold text-center lg:block lg:mb-8">
            {messages.cn.castingSurfaceTreatmentSourcing}
          </div>
          <div
            className="flex flex-col items-center justify-center w-full lg:flex-row"
            id="casting"
          >
            <div className="flex justify-center w-10/12 px-4 pb-4 sm:w-full sm:px-10 lg:pb-0 lg:w-6/12">
              <img
                src={casting}
                alt="Casting/Surface Treatment/Sourcing"
                className="w-auto h-full rounded-xl"
              />
            </div>
            <div className="self-stretch flex-1">
              <div className="flex flex-col w-full h-full pl-0 mb-6 text-left md:pl-10 md:mb-0">
                <div className="mt-1 mb-4 text-2xl text-center lg:mt-0 lg:mb-2 lg:text-left lg:hidden">
                  {messages.cn.castingSurfaceTreatmentSourcing}
                </div>
                <div className="my-auto">
                  <ul>
                    <li className="bullet-list">{messages.cn.castingText1}</li>
                    <li className="bullet-list">{messages.cn.castingText2}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-8">
        <div className="px-4 mx-auto sm:px-10 lg:px-20 xl:container">
          <div className="hidden mb-4 text-2xl font-semibold text-center lg:block lg:mb-8">
            {messages.cn.assembly}
          </div>
          <div
            className="flex flex-col items-center justify-center w-full lg:flex-row"
            id="assembly"
          >
            <div className="flex justify-center w-10/12 px-4 pb-4 sm:w-full sm:px-10 lg:pb-0 lg:w-6/12">
              <img
                src={assembly}
                alt="Assembly"
                className="w-auto h-full rounded-xl"
              />
            </div>
            <div className="self-stretch flex-1">
              <div className="flex flex-col w-full h-full pl-0 mb-6 text-left md:pl-10 md:mb-0">
                <div className="mt-1 mb-4 text-2xl text-center lg:mt-0 lg:mb-2 lg:text-left lg:hidden">
                  {messages.cn.assembly}
                </div>
                <div className="my-auto">
                  <ul>
                    <li className="bullet-list">{messages.cn.assemblyText1}</li>
                    <li className="bullet-list">{messages.cn.assemblyText2}</li>
                    <li className="bullet-list">{messages.cn.assemblyText3}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-8 bg-gray-200">
        <div className="px-4 mx-auto sm:px-10 lg:px-20 xl:container">
          <div className="hidden mb-4 text-2xl font-semibold text-center lg:block lg:mb-8">
            {messages.cn.qualityAssurance}
          </div>
          <div
            className="flex flex-col items-center justify-center w-full lg:flex-row"
            id="qualityAssurance"
          >
            <div className="flex justify-center w-10/12 px-4 pb-4 sm:w-full sm:px-10 lg:pb-0 lg:w-6/12">
              <img
                src={qualityAssurance}
                alt="Quality Assurance"
                className="w-auto h-full rounded-xl"
              />
            </div>
            <div className="self-stretch flex-1">
              <div className="flex flex-col w-full h-full pl-0 mb-6 text-left md:pl-10 md:mb-0">
                <div className="mt-1 mb-4 text-2xl text-center lg:mt-0 lg:mb-2 lg:text-left lg:hidden">
                  {messages.cn.qualityAssurance}
                </div>
                <div className="my-auto">
                  <ul>
                    <li className="bullet-list">
                      {messages.cn.qualtiyAssuranceText1}
                    </li>
                    <li className="bullet-list">
                      {messages.cn.qualtiyAssuranceText2}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        //#endregion
      }

      <Footer cn />
    </main>
  );
};

export default ServicesPage;
